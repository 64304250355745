import $ from 'wee-dom';

/**
 * Set height of accordion panel
 * @param {Wee obj} $panel
 */
const setHeight = ($panel) => {
    const $accordionPanel = $($panel);

    $accordionPanel.height(`${$accordionPanel.find(':panelHeading').height()}px`);
};

/**
 * Expand FAQ question wrapper to height of corresponding answer
 * @param {Wee obj} $panel Accordion panel to expand
 * @param {Wee obj} $content Content within panel
 */
const expand = ($panel, $content) => {
    $panel.height(`${$panel.height() + $content.height()}px`);
};

/**
 * Collapse FAQ wrapper to original height
 * @param {Wee obj} $el Element to collapse
 * @param {int} val Value of element height
 */
const collapse = ($el, val) => {
    $el.height(`${val}px`);
};

export {
    setHeight,
    expand,
    collapse,
};