import $ from 'wee-dom';
import $events from 'wee-events';
import { $setVar } from 'wee-store';
import { $setRef } from 'core/dom';
import { $chain } from 'core/chain';
import { noOutline } from './utilities';
import { expand, collapse } from '../components/accordion/functions';
import 'lazysizes';
import 'objectFitPolyfill';
import 'es6-promise/auto';
import '../styles/global.scss';

// Import all component scss files
require.context('../components', true, /\.scss$/);

$setRef();
$setVar();

$events.on({
    a: {
        mousedown(e, el) {
            noOutline(el);
        },
    },
    button: {
        mousedown(e, el) {
            noOutline(el);
        },
    },
    ':panelWrapper': {
        mousedown(e, el) {
            noOutline(el);
        },
    },
    input: {
        mousedown(e, el) {
            noOutline(el);
        },
    },
    ':tab': {
        mousedown(e, el) {
            noOutline(el);

            $(el).css('boxShadow', '0 0 5px 1px transparent');
        },
    },
}, {
    delegate: 'body',
});

$chain({
    /**
     * Toggle accordion panel
     */
    togglePanel() {
        const $content = this.find(':panelContent');
        const $heading = this.find(':panelHeading');

        if (this.hasClass('-expand')) {
            expand(this, $content);
        } else {
            collapse(this, this.height(`${$heading.height()}px`));
        }

        this.toggleAria('expanded');
        $content.toggleAria('hidden');

        return this;
    },

    /**
     * Toggle ARIA attribute
     * @param {string} attribute to toggle
     */
    toggleAria(attr) {
        if (this.attr(`aria-${attr}`) === 'true') {
            this.attr(`aria-${attr}`, 'false');
        } else {
            this.attr(`aria-${attr}`, 'true');
        }

        return this;
    },

    /**
     * Toggle tabindex attribute
     */
    toggleTabindex() {
        if (this.attr('tabindex') && this.attr('tabindex') === '-1') {
            this.attr('tabindex', '0');
        } else {
            this.attr('tabindex', '-1');
        }

        return this;
    },

    /**
     * Set tabindex
     * @param {string} val
     */
    tabindex(val) {
        this.attr('tabindex', val);

        return this;
    },
});
