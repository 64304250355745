import $router from 'wee-routes';
import './bootstrap';

/**
 * Dynamically load a component
 * @param {String} component
 */

const accordion = () => import(/* webpackChunkName: "accordion" */ '../components/accordion')
    .then((m) => m.default || m);
const csrf = () => import(/* webpackChunkName: "csrf" */ '../components/csrf')
    .then((m) => m.default || m);
const faqs = () => import(/* webpackChunkName: "faq" */ '../components/faq')
    .then((m) => m.default || m);
const header = () => import(/* webpackChunkName: "header" */ '../components/header')
    .then((m) => m.default || m);
const iAm = () => import(/* webpackChunkName: "i-am" */ '../components/i-am')
    .then((m) => m.default || m);
const cards = () => import(/* webpackChunkName: "cards" */ '../components/cards')
    .then((m) => m.default || m);
const sidebar = () => import(/* webpackChunkName: "sidebar" */ '../components/sidebar')
    .then((m) => m.default || m);
const sidenav = () => import(/* webpackChunkName: "sidenav" */ '../components/sidenav')
    .then((m) => m.default || m);
const heroFilters = () => import(/* webpackChunkName: "hero-filters" */ '../components/hero-filters')
    .then((m) => m.default || m);
const mobileFilters = () => import(/* webpackChunkName: "mobile-filters" */ '../components/mobile-filters')
    .then((m) => m.default || m);
const searchResults = () => import(/* webpackChunkName: "search-results" */ '../components/search-results')
    .then((m) => m.default || m);
const searchForm = () => import(/* webpackChunkName: "search-form" */ '../components/search-form')
    .then((m) => m.default || m);
const newsIndex = () => import(/* webpackChunkName: "news-index" */ '../components/news-index')
    .then((m) => m.default || m);
const eventsIndex = () => import(/* webpackChunkName: "events-index" */ '../components/events-index')
    .then((m) => m.default || m);
const careerEntry = () => import(/* webpackChunkName: "career-entry" */ '../components/career-entry')
    .then((m) => m.default || m);
const forms = () => import(/* webpackChunkName: "forms" */ '../components/forms')
    .then((m) => m.default || m);
const notFound = () => import(/* webpackChunkName: "not-found" */ '../components/not-found')
    .then((m) => m.default || m);
const homepage = () => import(/* webpackChunkName: "homepage" */ '../components/homepage')
    .then((m) => m.default || m);
const spinner = () => import(/* webpackChunkName: "spinner" */ '../components/spinner')
    .then((m) => m.default || m);
const quickCapture = () => import(/* webpackChunkName: "quick-capture" */ '../components/quick-capture')
    .then((m) => m.default || m);

const common = [
    csrf,
    header,
    searchForm,
    sidenav,
    sidebar,
];

$router.map([
    {
        path: '/',
        handler: [...common, homepage, quickCapture],
    },
    {
        path: '/i-am',
        handler: [...common, iAm],
    },
    {
        path: '/support',
        children: [
            {
                path: 'frequently-asked-questions',
                handler: [...common, faqs, mobileFilters, accordion],
            },
            {
                path: '*',
                handler: [...common, careerEntry],
            },
        ],
    },
    {
        path: '/academics',
        handler: [...common, sidebar, cards],
        children: [
            {
                path: 'degrees-and-programs',
                handler: [...common, mobileFilters, heroFilters, spinner],
            },
            {
                path: 'degrees-and-programs/:slug',
                handler: [...common, sidebar, cards, accordion, quickCapture],
            },
            {
                path: 'colleges-and-schools',
                handler: [...common, sidebar, cards, accordion],
            },
            {
                path: 'colleges/:slug',
                handler: [...common, sidebar, accordion, quickCapture],
            },
        ],
    },
    {
        path: '/about',
        handler: [...common, sidebar, cards, accordion],
        children: [
            {
                path: 'faculty-and-staff',
                handler: [...common, mobileFilters, heroFilters],
            },
            {
                path: '*',
                handler: [...common, careerEntry, accordion],
            },
        ],
    },
    {
        path: '/search-results',
        handler: [...common, searchResults],
    },
    {
        path: '/admissions',
        handler: [...common, sidebar, cards, accordion],
        children: [
            {
                path: 'application-process',
                handler: [...common, sidebar, cards, accordion],
            },
            {
                path: 'tuition-and-fees',
                handler: [...common, sidebar],
            },
        ],
    },
    {
        path: '/contact-us',
        handler: [...common],
    },
    {
        path: '/news',
        handler: [...common, mobileFilters, heroFilters, newsIndex],
    },
    {
        path: '/employees',
        handler: [...common, mobileFilters],
        children: [
            {
                path: 'careers',
                handler: [...common, mobileFilters, heroFilters],
                children: [
                    {
                        path: '*',
                        handler: [...common, careerEntry],
                    },
                ],
            },
        ],
    },
    {
        path: '/events',
        handler: [...common, mobileFilters, heroFilters, eventsIndex],
    },
    {
        path: '*',
        handler: [...common, sidebar, accordion, forms, notFound, careerEntry],
    },
]).run();
