import $ from 'wee-dom';
import $events from 'wee-events';

/**
 * Add/Remove outline styles
 * @param {element} element
 */
const noOutline = (element) => {
    const $el = $(element);

    if ($el.css('outline-style') === 'none') {
        const outlineClass = '-no-outline';

        $el.addClass(outlineClass);

        $events.on($el, 'blur', () => {
            $el.removeClass(outlineClass);
        }, {
            once: true,
        });
    }
};

/* eslint-disable-next-line */
const log = (...args) => console.log(...args);

/* eslint-disable-next-line */
const error = (...args) => console.error(...args);

export { noOutline, log, error };
